export const COUNTRIES = {
  CUBA: {
    value: 'Cuba',
    key: '56',
    alpha_code2: 'CU',
  },
  USA: {
    value: 'United States',
    key: '233',
    alpha_code2: 'US',
  },
}
