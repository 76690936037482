import { create } from 'zustand'
import { persist } from 'zustand/middleware'
import type { Country, LocationSchema } from './register.types'

const defaultValues = {
  cubanProvinces: [],
  usProvinces: [],
  countries: [],
  selectedCountry: null,
  provinceData: null,
  countryData: null,
}

export type AddressStates = {
  cubanProvinces: LocationSchema[]
  usProvinces: LocationSchema[]
  countries: Country[]
  selectedCountry: Country | null
  provinceData: Record<
    string,
    {
      cities: LocationSchema[]
    }
  > | null
  countryData: Record<
    string,
    {
      states: LocationSchema[]
    }
  > | null
}

export type ProvinceActions = {
  setCubanProvinces: (provinces: LocationSchema[]) => void
  setUsProvinces: (provinces: LocationSchema[]) => void
  setCountries: (countries: Country[]) => void
  setProvinceData: (cities: LocationSchema[], province: string) => void
  setSelectedCountry: (country: Country) => void
  setCountryData: (states: LocationSchema[], country: string) => void
}

export const useAddressStore = create<AddressStates & ProvinceActions>()(
  persist(
    (set) => ({
      ...defaultValues,
      setCountries: (countries) => set({ countries }),
      setCubanProvinces: (provinces) => set({ cubanProvinces: provinces }),
      setUsProvinces: (provinces) => set({ usProvinces: provinces }),
      setSelectedCountry: (selectedCountry) => set({ selectedCountry }),
      setCountryData: (states, country) =>
        set((state) => ({
          countryData: {
            ...state.countryData,
            [country]: {
              ...state[country],
              states,
            },
          },
        })),
      setProvinceData: (cities, province) =>
        set((state) => ({
          provinceData: {
            ...state.provinceData,
            [province]: {
              ...state[province],
              cities,
            },
          },
        })),
    }),
    {
      name: 'address-store',
    }
  )
)
