import * as Sentry from '@sentry/nextjs'

export const captureException = (
  error: unknown,
  context?: Record<string, unknown>
) => {
  const isLocal = process.env.NEXT_PUBLIC_ENVIRONMENT === 'local'

  if (!isLocal && process.env.NEXT_PUBLIC_ENVIRONMENT) {
    Sentry.captureException(error, {
      extra: context,
    })
  } else {
    console.error(error)
  }
}
