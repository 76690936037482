export const ACCEPTED_IMAGE_TYPES = [
  'image/jpeg',
  'image/jpg',
  'image/png',
  'image/webp',
]

export const MAX_FILE_SIZE_BT = 1024000

export const NAUTA_DOMAIN = '@nauta.com.cu'

export const PAYMENT_STATUS = [
  { key: 10, value: 'PENDING' },
  { key: 100, value: 'CANCELED' },
  { key: 1, value: 'COMPLETED' },
  { key: 2, value: 'DECLINED' },
  { key: 3, value: 'REJECTED' },
  { key: 4, value: 'FULL_REFUND' },
  { key: 5, value: 'PARTIAL_REFUND' },
  { key: 6, value: 'FULL_REFUND_TO_BALANCE' },
  { key: 500, value: 'GATEWAY_ERROR' },
  { key: 11, value: 'INITIATED_3DS' },
]

export const shipmentControlNumberRegex = /^[A-Z0-9]{5}-[A-Z0-9]{3}$/
export const passportControlNumberRegex = /^[A-Z0-9]{4}-[A-Z0-9]{3}$/
export const numberWithTwoDigitsRegex = /^\d+\.\d{2}$/

export const LOCALE_COOKIE = 'NEXT_LOCALE'
