'use client'
import { FormattedMessage } from '@/components/formatted-message'

export const useGender = () => {
  const GENDER_OPTIONS = [
    {
      key: 'Femenino',
      value: FormattedMessage({
        id: 'register.female',
      }),
    },
    {
      key: 'Masculino',
      value: FormattedMessage({
        id: 'register.male',
      }),
    },
    {
      key: 'Otro',
      value: FormattedMessage({
        id: 'register.other',
      }),
    },
  ]

  const filterByGender = (key: string) =>
    GENDER_OPTIONS.find((item) => item.key === key)?.key || ''

  return {
    states: {
      GENDER_OPTIONS,
    },
    actions: {
      filterByGender,
    },
  }
}
