'use client'

import type { TranslationValues } from 'next-intl'
import { useTranslations } from 'next-intl'
import type { Path } from 'react-hook-form'
import type EnMessages from '@/messages/en.json'
import type EsMessages from '@/messages/es.json'

export type MessagesIntlId = Path<typeof EnMessages | typeof EsMessages>

const FormattedMessage = ({
  id,
  values = {},
}: {
  id: MessagesIntlId
  values?: TranslationValues
}) => {
  const t = useTranslations()

  return t(id, values)
}

export { FormattedMessage }
