import { request, responseAdapter, type DeviceResponse } from 'libs'
import type { UserPersonalInfo } from '@/services/profile'
import { getHeaders } from '@/shared/utils'
import type {
  CitiesResponse,
  CountryResponse,
  DeleteAccountResponse,
  RegisterEmailRequest,
  RegisterEmailResponse,
  RegisterPhoneRequest,
  RegisterPhoneResponse,
  RegisterUserResponse,
  StateResponse,
} from './register.types'

export const getCountries = async (): Promise<CountryResponse> => {
  const response = await request.get(`/nomenclatures/countries`, getHeaders({}))

  const data = await response.json()

  return responseAdapter({ data, status: response.status })
}

export const getStates = async (countryId: string): Promise<StateResponse> => {
  const response = await request.get(
    `/nomenclatures/states?countryId=${countryId}`,
    getHeaders({})
  )

  const data = await response.json()

  return responseAdapter({ data, status: response.status })
}

export const getCities = async (
  countryId: string,
  stateId: string
): Promise<CitiesResponse> => {
  const searchParams = new URLSearchParams({
    countryId,
    stateId,
  })

  const response = await request.get(
    `/nomenclatures/cities?${searchParams.toString()}`,
    getHeaders({})
  )

  const data = await response.json()

  return responseAdapter({ data, status: response.status })
}

export const registerUser = async (
  user: Omit<UserPersonalInfo, 'gender'>
): Promise<RegisterUserResponse> => {
  const response = await request.post(
    '/register',
    {
      phone: user.phone,
      user,
    },
    {
      headers: getHeaders().headers,
    }
  )

  const data = await response.json()

  return responseAdapter({ data, status: response.status })
}

export const registerPhone = async ({
  phone,
  device,
  fcmToken,
}: {
  phone: RegisterPhoneRequest
  device: DeviceResponse
  fcmToken?: string
}): Promise<RegisterPhoneResponse> => {
  const response = await request.post(
    '/register/phone',
    {
      ...phone,
      device: {
        ...device,
        fcmToken,
      },
    },
    {
      headers: getHeaders().headers,
    }
  )

  const data = await response.json()

  return responseAdapter({ data, status: response.status })
}

export const registerEmail = async ({
  email,
  accessToken,
  device,
  fcmToken,
}: {
  email: RegisterEmailRequest
  accessToken?: string
  device: DeviceResponse
  fcmToken?: string
}): Promise<RegisterEmailResponse> => {
  const response = await request.post(
    '/register/email',
    {
      ...email,
      device: {
        ...device,
        fcmToken,
      },
    },
    {
      headers: getHeaders({
        accessToken,
      }).headers,
    }
  )
  const data = await response.json()

  return responseAdapter({ data, status: response.status })
}

export const deleteAccount = async ({
  accessToken,
}: {
  accessToken?: string
}): Promise<DeleteAccountResponse> => {
  const response = await request.post(
    '/register/delete',
    {},
    {
      headers: getHeaders({
        accessToken,
      }).headers,
    }
  )
  const data = await response.json()

  return responseAdapter({ data, status: response.status })
}
