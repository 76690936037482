'use client'

import { useEffect, useState } from 'react'
import { useAsync } from 'react-use'
import type { LocationSchema } from '@/services'
import { useAddressStore } from '@/services/authentication/register/address.store'
import { getCities } from '@/services/authentication/register/register.service'
import type { User } from '@/services/profile'
import { COUNTRIES } from '../constants/base-countries'

//TODO: @Deprecated
export const useAddressForm = ({
  addressStore: store,
  isCubanCountry = false,
}: {
  addressStore?: User['address']
  isCubanCountry?: boolean
}) => {
  const provinces = useAddressStore((state) => {
    return isCubanCountry ? state.cubanProvinces : state.usProvinces
  })

  const [selectedProvince, setSelectedProvince] = useState<LocationSchema>()
  const [selectedCity, setSelectedCity] = useState<LocationSchema>()

  const selectedCountry = isCubanCountry ? COUNTRIES.CUBA : COUNTRIES.USA

  const { loading: loadingCities, value: cities = [] } = useAsync(async () => {
    if (selectedProvince) {
      const { data } = await getCities(
        selectedCountry.key,
        selectedProvince.key
      )
      return data.cities
    }
  }, [selectedProvince])

  const findItemByKey = <T extends LocationSchema>(
    items: T[],
    key: number
  ): T | undefined => {
    return items.find((item) => item.key === key.toString())
  }

  useEffect(() => {
    if (provinces.length && store?.stateId) {
      const result = findItemByKey(provinces, store.stateId)
      if (result) setSelectedProvince(result)
    }
  }, [provinces, store?.stateId])

  useEffect(() => {
    if (cities.length && store?.cityId) {
      const result = findItemByKey(cities, store.cityId)
      if (result) setSelectedCity(result)
    }
  }, [cities, store?.cityId])

  return {
    actions: {
      findItemByKey,
      setSelectedCity,
      setSelectedProvince,
    },
    states: {
      cities,
      provinces,
      selectedCity,
      selectedCountry,
      selectedProvince,
      loadingCities,
    },
  }
}
