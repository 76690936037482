import { getCookies } from 'cookies-next'
import type { SERVICE_VALUE } from 'libs'
import { SERVICE_TYPE } from 'libs'
import pkg from '../../../package.json'
import { PAYMENT_STATUS } from '../constants/utils'
import { WebRoutes } from '../routes/web.routes'

export * from './exceptions.utils'

export const version = pkg.version

export const scrollToTop = () => {
  window.scrollTo({ top: 0, behavior: 'smooth' })
}

export const capitalizeFirstLetter = (value: string) => {
  return value.charAt(0).toUpperCase() + value.slice(1)
}

export const isEmpty = <T>(value: T) => {
  return (
    value === undefined ||
    value === null ||
    value === '' ||
    (typeof value === 'object' && Object.keys(value).length === 0)
  )
}

export const getResponse = async <T>(
  responsePromise: Promise<Response>
): Promise<T> => {
  const response = await responsePromise
  return response.json()
}

export const formatCurrencyUSD = (value: number) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(value)
}

const getLocale = () => getCookies().NEXT_LOCALE

export const getHeaders = (params?: {
  accessToken?: string
  locale?: string
  customHeaders?: HeadersInit
}) => {
  return {
    headers: {
      ...params?.customHeaders,
      ...(params?.accessToken && {
        Authorization: `Bearer ${params.accessToken}`,
      }),
      'Accept-Language': params?.locale || getLocale() || 'es',
      app: 'web',
    },
  }
}

export const compareDates = (
  date1: Date | string | number,
  date2: Date | string | number
) => {
  const d1 = new Date(date1)
  const d2 = new Date(date2)

  d1.setHours(0, 0, 0, 0)
  d2.setHours(0, 0, 0, 0)

  return d1.getTime() < d2.getTime()
}

export const disabledDate = (date: Date) => compareDates(date, new Date())

export function formatServiceKey(serviceKey: string): string {
  const words = serviceKey.split('_')

  const camelCase = words.map((word) => {
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
  })
  return camelCase.join('').toLowerCase()
}

export const paymentStatus = (paymentStatusId: number) =>
  PAYMENT_STATUS.find(
    (item) => item.key.toString() === paymentStatusId.toString()
  )?.value || ''

export const paymentStatusType = (paymentStatusId: number) => {
  switch (paymentStatus(paymentStatusId)) {
    case 'INITIAL':
      return 'default'
    case 'COMPLETED':
      return 'default'
    case 'FULL_REFUND':
      return 'default'
    case 'FULL_REFUND_TO_BALANCE':
      return 'default'
    case 'PENDING':
      return 'secondary'
    case 'PARTIAL_REFUND':
      return 'secondary'
    default:
      return 'destructive'
  }
}

export const getServiceByKey = (serviceTypeId: number) =>
  Object.values(SERVICE_TYPE).find(
    (item) => item.key.toString() === serviceTypeId.toString()
  )?.value || ''

export const getRouteByService = (serviceKeyTextButton: string) => {
  const upperCaseServiceKeyTextButton = serviceKeyTextButton.toUpperCase()

  const serviceRouteLookup: Partial<Record<SERVICE_VALUE, WebRoutes>> = {
    [SERVICE_TYPE.REMITTANCE.value]: WebRoutes.Remittance,
    [SERVICE_TYPE.TOPUP.value]: WebRoutes.Topup,
    [SERVICE_TYPE.SHOPPING.value]: WebRoutes.ShoppingCategories,
    [SERVICE_TYPE.CONSULAR_SERVICES.value]: WebRoutes.Consul,
    [SERVICE_TYPE.CAR.value]: WebRoutes.CarRent,
    [SERVICE_TYPE.BALANCE.value]: WebRoutes.ProfileBalance,
    [SERVICE_TYPE.CALL.value]: WebRoutes.Call,
    [SERVICE_TYPE.SMS.value]: WebRoutes.Sms,
    [SERVICE_TYPE.STORE.value]: WebRoutes.Shop,
    [SERVICE_TYPE.LOCKER.value]: WebRoutes.VirtualLocker,
    [SERVICE_TYPE.HOTEL.value]: WebRoutes.Hotel,
    [SERVICE_TYPE.FLIGHTS.value]: WebRoutes.Flights,
  }

  return serviceRouteLookup[upperCaseServiceKeyTextButton] ?? null
}

export const getHistoryRouteByService = (serviceKeyTextButton: string) => {
  const upperCaseServiceKeyTextButton = serviceKeyTextButton.toUpperCase()

  const historyServiceRouteLookup: Partial<Record<SERVICE_VALUE, string>> = {
    [SERVICE_TYPE.SMS.value]: WebRoutes.SmsHistory,
    [SERVICE_TYPE.STORE.value]: WebRoutes.ShopHistory,
    [SERVICE_TYPE.TOPUP.value]: WebRoutes.TopupsHistory,
    [SERVICE_TYPE.SHOPPING.value]: WebRoutes.ShoppingHistory,
    [SERVICE_TYPE.REMITTANCE.value]: WebRoutes.RemittanceHistory,
    [SERVICE_TYPE.LOCKER.value]: WebRoutes.VirtualLockerHistory,
    [SERVICE_TYPE.BALANCE.value]: `${WebRoutes.ProfileBalance}?tab=history`,
  }

  return historyServiceRouteLookup[upperCaseServiceKeyTextButton] ?? null
}

export const getServiceKeyText = (serviceKey: string) =>
  Object.values(SERVICE_TYPE).find(
    (service) => service.key.toString() === serviceKey
  )?.value

export const cleanSearchString = (str: string) => {
  const regex = /null\\n|null|\n/g
  return str.replace(regex, '').replaceAll(' ', '').toLowerCase()
}

export const validateNumberWithTwoDecimal = (value: string) => {
  const splitValue = value.split('.')
  const digits = splitValue[1]

  if (digits && digits.length === 1) return Number(splitValue[1]) > 0
  else if (digits && digits.length === 2) {
    return !digits.includes('00')
  }
  return true
}

export const luhnChk = ({
  input,
  limit = 14,
}: {
  input: string
  limit?: number
}): boolean => {
  const withoutSpaces = input.replace(/\s+/g, '')

  if (withoutSpaces.length < limit) {
    return false
  }

  let sum = 0
  const length = withoutSpaces.length
  for (let i = 0; i < length; i++) {
    let digit = parseInt(withoutSpaces[length - i - 1], 10)
    if (i % 2 === 1) {
      digit *= 2
    }
    sum += digit > 9 ? digit - 9 : digit
  }

  return sum % 10 === 0
}

export const pushQueriesParamsToUrl = (
  queriesParams: Record<string, string>
) => {
  const searchParams = new URLSearchParams(queriesParams)

  return window.history.pushState(null, '', `?${searchParams.toString()}`)
}

export const transformImageToFile = async (imageUrl: string) => {
  const response = await fetch(imageUrl)
  const blob = await response.blob()
  const newFile = new File([blob], 'filename.png', {
    type: 'image/png',
  })

  return newFile
}

export const transformImageToBase64 = (file: File) =>
  new Promise<string>((resolve) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result as string)
  })
