import { SERVICE_TYPE } from 'libs'
import { createRouteRegex } from '../utils/routes.utils'

export const WebRoutes = {
  Login: '/login',
  Register: '/register',
  Faq: '/faq',
  Privacy: '/privacy',
  TermsAndCondition: '/terms-and-conditions',
  Profile: '/profile',
  ProfileAddress: '/profile/address',
  ProfilePaymentMethods: '/profile/payment-methods',
  ProfileContacts: '/profile/contacts',
  ProfileBalance: '/profile/balance',
  ProfilePaymentHistory: '/profile/payment-history',
  ProfileIdentityDocuments: '/profile/identity-documents',
  Home: '/',
  About: '/about',
  OurOffices: '/our-offices',
  // CHECKOUT
  Checkout: '/checkout',
  CheckoutTopup: '/checkout/topup',
  CheckoutRemittance: '/checkout/remittance',
  CheckoutShopping: '/checkout/shopping',
  CheckoutStore: '/checkout/store',
  CheckoutBalance: '/checkout/balance',
  CheckoutValidateOperation: '/checkout/validate-operation',
  CheckoutFlights: '/checkout/flights',
  // REMITTANCES
  Remittance: '/remittance',
  RemittanceHistory: '/remittance/history',
  // TOPUPS
  Topup: '/topups',
  TopupsHistory: '/topups/history',
  // SHOPPING
  Shopping: '/shopping',
  ShoppingHistory: '/shopping/history',
  ShoppingProducts: '/shopping/products',
  ShoppingCategories: '/shopping/categories',
  ShoppingCart: '/shopping/cart',
  // TODO: Change the route name to /store
  // STORE
  Shop: '/shop',
  ShopHistory: '/shop/history',
  ShopProducts: '/shop/products',
  ShopProductDetail: '/shop/product',
  ShopCart: '/shop/cart',
  ShopCheckout: '/shop/checkout',
  ShopLocation: '/shop/location',
  // SMS
  Sms: '/sms',
  SmsHistory: '/sms/history',
  // VIRTUAL LOCKER
  VirtualLocker: '/virtual-locker',
  VirtualLockerHistory: '/virtual-locker/history',
  VirtualLockerAddShipment: '/virtual-locker/add-shipment',
  VirtualLockerEditShipment: '/virtual-locker/edit-shipment/[shipmentId]',
  // CONSUL
  Consul: '/consul',
  // CAR-RENT
  CarRent: '/car-rent',
  // HOTEL
  Hotel: '/hotel',
  // FLIGHTS
  Flights: '/flights',
  FlightsHistory: '/flights/history',
  // CALL
  Call: '/call',
  TrackingPassport: '/tracking/passport',
  TrackingShipment: '/tracking/shipment',
} as const

export type WebRoutes = (typeof WebRoutes)[keyof typeof WebRoutes]

type LooseAutocompleteHistoryLink<T extends string> =
  | T
  | Omit<`${string}/history`, T>

export type HistoryLink<T = WebRoutes> =
  | (T extends `${infer U}/history`
      ? LooseAutocompleteHistoryLink<`${U}/history`>
      : never)
  | `${(typeof WebRoutes)['ProfileBalance']}?tab=history`

export const protectedRoutes = [
  WebRoutes.Profile,
  WebRoutes.ProfileAddress,
  WebRoutes.ProfilePaymentMethods,
  WebRoutes.ProfileContacts,
  WebRoutes.ProfileBalance,
  WebRoutes.ProfilePaymentHistory,
  WebRoutes.ProfileIdentityDocuments,
  WebRoutes.Checkout,
  WebRoutes.Remittance,
  WebRoutes.RemittanceHistory,
  WebRoutes.Topup,
  WebRoutes.TopupsHistory,
  WebRoutes.CheckoutTopup,
  WebRoutes.CheckoutRemittance,
  WebRoutes.CheckoutShopping,
  WebRoutes.CheckoutBalance,
  WebRoutes.CheckoutFlights,
  WebRoutes.ShoppingHistory,
  WebRoutes.ShopHistory,
  WebRoutes.Sms,
  WebRoutes.SmsHistory,
  WebRoutes.CheckoutValidateOperation,
  WebRoutes.VirtualLocker,
  WebRoutes.VirtualLockerAddShipment,
  WebRoutes.VirtualLockerHistory,
  WebRoutes.FlightsHistory,
]

export type ProtectedRoutes = (typeof protectedRoutes)[number]

export const serviceRouteMap: [SERVICE_TYPE, RegExp][] = [
  [SERVICE_TYPE.REMITTANCE.value, createRouteRegex(WebRoutes.Remittance)],
  [SERVICE_TYPE.TOPUP.value, createRouteRegex(WebRoutes.Topup)],
  [SERVICE_TYPE.SHOPPING.value, createRouteRegex(WebRoutes.Shopping)],
  [SERVICE_TYPE.CONSULAR_SERVICES.value, createRouteRegex(WebRoutes.Consul)],
  [SERVICE_TYPE.CAR.value, createRouteRegex(WebRoutes.CarRent)],
  [SERVICE_TYPE.BALANCE.value, createRouteRegex(WebRoutes.ProfileBalance)],
  [SERVICE_TYPE.CALL.value, createRouteRegex(WebRoutes.Call)],
  [SERVICE_TYPE.SMS.value, createRouteRegex(WebRoutes.Sms)],
  [SERVICE_TYPE.STORE.value, createRouteRegex(WebRoutes.Shop)],
  [SERVICE_TYPE.LOCKER.value, createRouteRegex(WebRoutes.VirtualLocker)],
  [SERVICE_TYPE.HOTEL.value, createRouteRegex(WebRoutes.Hotel)],
  [SERVICE_TYPE.FLIGHTS.value, createRouteRegex(WebRoutes.Flights)],
]

export const enum QueryParams {
  SERVICES_DETAILS_ID = 'serviceDetailsId',
  OPERATION_ID = 'operationId',
  CART_ID = 'cart',
}
